<template>

  <div class="checkout-items">
    <b-card
      v-for="(file, index) in cartItems"
      :key="file.id"
      no-body
      class="mb-1"
    >

      <!-- Product Details: Card Body -->
      <b-card-body class="py-0 pl-1 pr-0">
        <div class="d-flex justify-content-between">
          <h6 style="margin-top: 10px;">
            <b class="text-body">
              {{ (index + 1) + ". " + file.kad }}
            </b>
            -
            <span class="text-success mb-1">{{ new Date(file.dateCreate).toLocaleDateString() }}</span>, <span class="item-company"><b-link class="company-name">{{ file.fileType }}</b-link></span>
          </h6>
          <b-button
            variant="light"
            class="remove-wishlist"
            @click="removeItemFromCart(file.id)"
          >
            <feather-icon
              icon="XIcon"
              class="mr-0"
            />
          </b-button>
        </div>
      </b-card-body>
    </b-card>
    <h4 v-if="!cartItems || cartItems.length == 0">В карзине нет файлов, Вы можете найти файлы в разделе "Архив"</h4>
  </div>

</template>

<script>
import {
  BCard, BCardBody, BLink, BButton,
} from 'bootstrap-vue'
import store from '@/store'
import { computed } from '@vue/composition-api'
import { formatDate } from '@core/utils/filter'

export default {
  components: {
    BCard, BCardBody, BLink, BButton,
  },
  setup() {
    const removeItemFromCart = idFile => {
      store.commit('app-ecommerce/REMOVE_PRODUCT_FROM_CART', idFile)
    }
    const cartItemsCount = computed(() => store.getters['app-ecommerce/cartItemCnt'])
    const cartItems = computed(() => store.getters['app-ecommerce/cartItems'])
    const selectFilesCount = computed(() => store.getters['app-ecommerce/selectFilesCnt'])
    const selectFiles = computed(() => store.getters['app-ecommerce/selectFiles'])

    return {
      // UI
      removeItemFromCart,
      // Filter
      formatDate,
      //
      cartItemsCount,
      cartItems,

      selectFilesCount,
      selectFiles,
    }
  },
}
</script>

<style>

</style>
