<template>
  <div class="list-view">

    <!-- Left Card -->
    <b-card no-body>
      <b-card-header class="flex-column align-items-start">
        <b-card-title>Подтверждение заказа</b-card-title>
        <b-card-text class="text-muted mt-25">
          После списания <feather-icon
            icon="WindIcon"
            class="mx-auto"
            color="red"
          /> Вам будут доступны выбранные файлы в разделе "Архив", так же получить файлы Вы сможете в разделе "Заказы"
        </b-card-text>
      </b-card-header>
      <b-card-body>
        <h5>Файлов в корзине: {{ cartItemsCount }} шт.</h5>
        <h5 class="mb-2">Файлов на сумму: {{ totalAmount }} <feather-icon
          icon="WindIcon"
          class="mx-auto"
          color="red"
        />.</h5>
        <b-button
          :disabled="isLoading"
          variant="primary"
          class="mb-1"
          @click="NewOrder"
        >
          Подтвердить заказ
          <b-spinner
            v-if="isLoading"
            style="width: 1rem; height: 1rem;"
            class="m-0 p-0"
          />
        </b-button>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import store from '@/store'
import { computed, ref } from '@vue/composition-api'
import {
  BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BButton, BSpinner,
} from 'bootstrap-vue'

export default {
  components: {
    // BSV
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BButton,
    BSpinner,
  },
  props: {
    paymentDetails: {
      type: Object,
      required: true,
    },
  },
  setup(props, ctx) {
    const isLoading = ref(false)
    const orderResult = ref({})
    const cartItems = computed(() => store.getters['app-ecommerce/cartItems'])
    const NewOrder = () => {
      isLoading.value = true
      store.dispatch('app-ecommerce/createNewOrder', store.getters['app-ecommerce/cartItems'].map(ii => ii.id)).then(response => {
        orderResult.value = response.data.Data
        for (let i = 0; i < orderResult.value.orderFiles.length; i += 1) {
          const orderfile = orderResult.value.orderFiles[i]
          if (orderfile.status === 'ok' || orderfile.status === 'file_not_exist' || orderfile.status === 'user_is_root' || orderfile.status === 'file_buy_before') {
            store.commit('app-ecommerce/REMOVE_PRODUCT_FROM_CART', orderfile.idFile)
          }
        }
        store.commit('app-ecommerce/SET_BALANCE', response.data.Data.balance)
        store.commit('app-ecommerce/SET_LOST_ORDER_INFO', response.data.Data)
        isLoading.value = false
        ctx.emit('next-step')
      })
    }

    const totalAmount = computed(() => {
      let total = 0
      store.getters['app-ecommerce/cartItems'].forEach(() => { total += 150 })
      return total
    })
    const cartItemsCount = computed(() => store.getters['app-ecommerce/cartItems'].length)
    return {
      cartItems,
      NewOrder,
      totalAmount,
      cartItemsCount,
      isLoading,
      orderResult,
    }
  },
}
</script>

<style lang="scss" scoped>
#cvv {
  width: auto;
}
</style>
