<template>
  <div class="list-view">
    <!-- Left Card -->
    <b-card v-if="lostOrderInfo && lostOrderInfo.idOrder" no-body>
      <b-card-header class="flex-column align-items-start">
        <b-card-title>Заказ №{{ lostOrderInfo.idOrder }} создан</b-card-title>
        <b-card-text class="mt-25">
          После обработки Вам будут доступны заказанные файлы в разделе "Архив", так же получить файлы Вы сможете в разделе "Заказы"
          <div v-if="lostOrderInfo.orderFiles.filter(ii=>ii.status !== 'ok').length">
            <hr>
            после предварительной обработки заказа в нём найдены следующие проблемы:<br>
            <ul>
              <li v-if="lostOrderInfo.orderFiles.filter(ii=>ii.status !== 'user_is_root').length">В заказе присутствовали файлы, которые Вы загрузили в архив (их не нужно приобретать, они Вам доступны бесплатно) {{ lostOrderInfo.orderFiles.filter(ii=>ii.status !== 'user_is_root').length }} - шт.</li>
              <li v-if="lostOrderInfo.orderFiles.filter(ii=>ii.status !== 'file_buy_before').length">В заказе присутствовали файлы, которые Вы приобрели ранее {{ lostOrderInfo.orderFiles.filter(ii=>ii.status !== 'file_buy_before').length }} - шт.</li>
              <li v-if="lostOrderInfo.orderFiles.filter(ii=>ii.status !== 'file_not_exist').length">В заказе присутствовали идентификаторы файлов которых нет в архиве {{ lostOrderInfo.orderFiles.filter(ii=>ii.status !== 'file_not_exist').length }} - шт.</li>
              <li v-if="lostOrderInfo.orderFiles.filter(ii=>ii.status !== 'file_other_err').length">В заказе присутствовали файлы в ходе покупки которых произошла ошибка {{ lostOrderInfo.orderFiles.filter(ii=>ii.status !== 'file_other_err').length }} - шт.</li>
            </ul>
            <hr>
            <h5>Файлов куплено: {{ lostOrderInfo.orderFiles.filter(ii=>ii.status !== 'ok').length }} шт.</h5>
            <h5 class="mb-2">Файлов куплено на сумму: {{ lostOrderInfo.orderFiles.filter(ii=>ii.status !== 'ok').length * 150 }} <feather-icon
              icon="WindIcon"
              class="mx-auto"
              color="red"
            />.</h5>
          </div>
        </b-card-text>
      </b-card-header>
      <b-card-body>
        <b-button
          variant="primary"
          class="mb-1 mr-1"
          :to="{ name: 'apps-excerpt-search-folder', params: { label: 'all', folder: 'all' } }"
        >
          В архив
        </b-button>
        <b-button
          variant="primary"
          class="mb-1"
          :to="{ name: 'apps-order-list-folder', params: { label: '90days', folder: 'all' } }"
        >
          К заказам
        </b-button>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import store from '@/store'
import { computed } from '@vue/composition-api'
import {
  BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BButton,
} from 'bootstrap-vue'

export default {
  components: {
    // BSV
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BButton,
  },
  props: {
    paymentDetails: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const lostOrderInfo = computed(() => store.getters['app-ecommerce/lostOrderInfo'])
    return {
      lostOrderInfo,
    }
  },
}
</script>

<style lang="scss" scoped>
#cvv {
  width: auto;
}
</style>
