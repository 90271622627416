<template>
  <div class="list-view product-checkout mt-0">

    <!-- Products List -->
    <e-commerce-checkout-step-cart-products />

    <!-- Checkout Options -->
    <div class="checkout-options">
      <b-card>

        <label class="section-label mb-1 mt-1">ИТОГО</label>
        <div class="price-details">
          <ul class="list-unstyled">
            <li class="price-detail">
              <div class="detail-title">
                Доступно
              </div>
              <div class="detail-amt">
                <b-link
                  class="mr-1"
                  @click="LoadingBalance"
                >
                  <feather-icon
                    icon="RefreshCwIcon"
                    size="21"
                  />
                </b-link>
                {{ balance }}
                <feather-icon
                  icon="WindIcon"
                  class="mx-auto"
                  color="red"
                />
              </div>
            </li>
            <li class="price-detail">
              <b class="detail-title">
                Выбрано файлов на
              </b>
              <b class="detail-amt">
                {{ totalAmount }}
                <feather-icon
                  icon="WindIcon"
                  class="mx-auto"
                  color="red"
                />
              </b>
            </li>
            <li class="price-detail">
              <b class="detail-title">
                Количество файлов
              </b>
              <b class="detail-amt">
                {{ cartItemsCount }} шт.
              </b>
            </li>
            <li
              v-if="difference >= 0"
              class="price-detail"
            >
              <div class="detail-title">
                Не хватает
              </div>
              <div class="detail-amt discount-amt text-danger">
                {{ totalAmount - balance }}
                <feather-icon
                  icon="WindIcon"
                  class="mx-auto"
                  color="red"
                />
              </div>
            </li>
            <li
              v-if="difference >= 0"
              class="price-detail"
            >
              <b-button
                variant="danger"
                block
                :to="{ name: 'pages-faq' }"
              >
                Как получить <feather-icon
                  icon="WindIcon"
                  class="mx-auto"
                /> ?
              </b-button>
            </li>
          </ul>
          <hr v-if="totalAmount > 0 && difference <= 0">

          <b-button
            v-if="totalAmount > 0 && difference <= 0"
            variant="primary"
            block
            @click="$emit('next-step')"
          >
            Купить и списать
            <feather-icon
              icon="WindIcon"
              class="mx-auto"
            />
          </b-button>
        </div>

      </b-card>
    </div>
  </div>
</template>

<script>
import {
  BButton, BCard, BLink,
} from 'bootstrap-vue'
import store from '@/store'
import { computed } from '@vue/composition-api'
import ECommerceCheckoutStepCartProducts from './ECommerceCheckoutStepCartProducts.vue'

export default {
  components: {
    // BSV
    BButton,
    BCard,
    BLink,
    // SFC
    ECommerceCheckoutStepCartProducts,
  },
  setup() {
    const balance = computed(() => store.getters['app-ecommerce/balance'])
    const totalAmount = computed(() => {
      let total = 0
      store.getters['app-ecommerce/cartItems'].forEach(() => { total += 150 })
      return total
    })
    const cartItemsCount = computed(() => store.getters['app-ecommerce/cartItems'].length)

    const difference = computed(() => {
      let total = 0
      store.getters['app-ecommerce/cartItems'].forEach(() => { total += 150 })
      return total - store.getters['app-ecommerce/balance']
    })

    const LoadingBalance = () => {
      store.dispatch('app-ecommerce/fetchUserBalance').then()
    }

    return {
      balance,
      LoadingBalance,
      totalAmount,
      cartItemsCount,
      difference,
    }
  },
}
</script>
